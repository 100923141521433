import SVTeamFeedPage from '@apps/www/src/pages/team.page';
import SVGridContainer from '@apps/www/src/www/containers/SVGridContainer';
import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import SVWithRequiredLogin from '@apps/www/src/www/containers/SVWithRequiredLogin';
import useAuthSubscriptionIsExpired from '@apps/www/src/www/hooks/useAuthSubscriptionIsExpired';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import usePaginatedQuery from '@apps/www/src/www/hooks/usePaginatedQuery';
import FeedItemsQuery from '@apps/www/src/www/queries/FeedItemsQuery';
import { sendGTMEvent } from '@next/third-parties/google';
import { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVGrid from '@pkgs/shared-client/components/SVGrid';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';
import { useMount } from 'react-use';

const _ExpiredNotice = () => {
	return (
		<div className="flex min-h-[calc(100vh-var(--nav-height)*2)] flex-col items-center justify-center">
			<div className="flex max-w-80 flex-col items-center justify-center space-y-4 text-center">
				<h1 className="type-small-title mb-4">Go back to Premium</h1>
				<p>Want to continue browsing ad-free inspiration curated by our&nbsp;team?</p>
				<p>Reactivate your subscription and continue getting&nbsp;inspired.</p>

				<div>
					<SVTrackedUpgradeButtonContainer
						className="mt-12"
						source={UpgradeButtonSource.BLOCKED_FEED}
						to="/upgrade/"
						use={SVButtonUSES.PRIMARY}
					>
						Let's go
					</SVTrackedUpgradeButtonContainer>
				</div>
			</div>
		</div>
	);
};

const _FeedGrid = () => {
	const { items, loading, paginate } = usePaginatedQuery(FeedItemsQuery, {
		// No SSR here to speed up first load, feed is usually a slow query.
		ssr: false,
		// No need to check for errors here as this is the only page the user can load to access navigation
		errorPolicy: 'ignore',
	});

	return (
		<SVGridContainer
			sourceType={SVGrid.SOURCE_TYPES.FEED}
			items={items}
			isLoading={loading}
			onPaginate={paginate}
		/>
	);
};

const _Feed = () => {
	const hasExpiredSubscription = Boolean(useAuthSubscriptionIsExpired());

	if (hasExpiredSubscription) {
		return <_ExpiredNotice />;
	}

	return <_FeedGrid />;
};

// forceRegularFeed is used in FeedPage to force the default feed query to be used
const _SVFeed = ({ forceRegularFeed }: { forceRegularFeed?: boolean }) => {
	const team = useAuthTeam();

	useMount(() => {
		sendGTMEvent('page_home_logged_in');
	});

	if (!(forceRegularFeed || !team?.teamFeedAsHomepage)) {
		return <SVTeamFeedPage />;
	}

	return <_Feed />;
};

const SVFeed = SVWithRequiredLogin(_SVFeed);

export default SVFeed;
