import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import { type BoardFragmentFragment } from '@apps/www/src/__generated__/graphql';
import { useRouter } from 'next/router';
import React from 'react';
import SVGrid from './SVGrid';
import SVLink from './SVLink';
import clsx from 'clsx';
import SVPageMargins from './SVPageMargins';

interface SVBoardsMenuProps {
  boards: BoardFragmentFragment[];
  feedboardid?: string;
  teamFeedAsHomepage?: boolean;
}

const _AllLink: React.FC<{ to: string }> = ({ to }) => (
  <SVLink to={to} className="flex items-center -md:whitespace-nowrap -mdp:mr-2">
    All
  </SVLink>
);

const _BoardLink: React.FC<{
  board: BoardFragmentFragment;
  isActive: boolean;
  hasFeedboardId: boolean;
}> = ({ board, isActive, hasFeedboardId }) => {
  const boardUrl = hasFeedboardId ? `/team/${board.slug}` : board.url;

  return (
    <li
      key={board._id}
      className={clsx('group rounded-lg -mdp:mr-2', {
        'items-center text-gray-200': isActive,
        'text-gray-500 transition duration-150 hover:text-gray-300': !isActive,
      })}
    >
      <SVLink to={boardUrl} className="-md:whitespace-nowrap">
        <div className="flex items-center">
          <p className="max-w-full truncate">{board.name}</p>
          <span className={clsx('ml-2', { underline: !isActive })}>
            ({board.itemsCount})
          </span>
        </div>
      </SVLink>
    </li>
  );
};

const isActive = (board: BoardFragmentFragment, routerPath: string) => {
  let boardPath = new URL(board.url).pathname;

  if (routerPath.startsWith('/team/') && !routerPath.includes('/boards/')) {
    routerPath = routerPath.replace('/team/', '/team/boards/');
  }
  
  return routerPath === boardPath;
};

const SVBoardsMenu: React.FC<SVBoardsMenuProps> = ({ boards, feedboardid, teamFeedAsHomepage }) => {
  const router = useRouter();
  const boardsArray = Array.isArray(boards) ? boards : [boards];
  const [sourceGridType] = useUIState(UIStateKeys.SOURCE_GRID_TYPE);
  const isTeamView = router.asPath.includes('/team') || teamFeedAsHomepage;

  const filteredBoards = boardsArray.filter(
    (board) => board.ownershipType === (isTeamView ? 'team' : 'user')
  );

  const activeBoard = filteredBoards.find((board) => isActive(board, router.asPath));

  return (
    <nav className="-sm:mt-7 -md:mt-4 -md:mb-9 sticky left-0 top-5 mt-3 h-auto w-ful h-auto w-full md:w-[240px]">
      <ul className="flex overflow-x-auto -md:pb-3 -md:mb-6 scrollbar-hide flex-col space-y-3 -md:flex-row -md:space-y-0">
      {!feedboardid ? (
          <li
            className={clsx('items-center', {
              'text-gray-200': router.asPath === '/' || router.asPath === '/team/',
              'text-gray-500 transition duration-150 hover:text-gray-300': router.asPath !== '/' && router.asPath !== '/team/',
            })}
          >
            {activeBoard && activeBoard.url.includes('/team') ? (
              <_AllLink to="/team" />
            ) : (
              <_AllLink to="/boards" />
            )}
          </li>
        ) : (
          filteredBoards
            .filter((board) => board._id === feedboardid)
            .map((board) => (
              <_BoardLink
                key={board._id}
                board={board}
                isActive={
                  (router.asPath === '/' || router.asPath === '/team/') 
                  ? board._id === feedboardid 
                  : isActive(board, router.asPath)
                }
                hasFeedboardId={!!feedboardid}
              />
            ))
        )}

        {filteredBoards
          .filter((board) => !feedboardid || board._id !== feedboardid)
          .map((board) => (
            <_BoardLink
              key={board._id}
              board={board}
              isActive={isActive(board, router.asPath)}
              hasFeedboardId={!!feedboardid}
            />
          ))}
      </ul>
    </nav>
  );
};

export default SVBoardsMenu;
