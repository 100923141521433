import { Board, Team } from '@apps/www/src/__generated__/graphql';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import useAuthBoards from '@apps/www/src/www/hooks/useAuthBoards';
import useAuthHasTeamPrivileges from '@apps/www/src/www/hooks/useAuthHasTeamPrivileges';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import SVA from '@pkgs/shared-client/components/SVA';
import SVBoardsMenu from '@pkgs/shared-client/components/SVBoardsMenu';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import clsx from 'clsx';
import minimatch from 'minimatch';
import { useRouter } from 'next/router';

const _Label = ({
	children,
	to,
	path,
}: {
	children: React.ReactNode;
	to: string;
	path: string;
}) => {
	const router = useRouter();

	return (
		<SVA
			Component={SVLink}
			to={to}
			className={clsx(
				'w-fit max-w-full whitespace-nowrap text-2xl',
				minimatch(router.asPath, path) ? 'text-primary' : 'text-gray-500',
			)}
		>
			{children}
		</SVA>
	);
};

const _BoardSelection = ({ teamBoards, team }: { teamBoards: Board[] | undefined; team: Team }) => {
	const showDefaultBoardLabel = !team.feedBoardID && teamBoards?.length === 0;

	return (
		<div>
			{showDefaultBoardLabel ? (
				<SVBoardsMenu boards={[]} />
			) : (
				<SVBoardsMenu
					boards={teamBoards || []}
					feedboardid={team.feedBoardID || ''}
					teamFeedAsHomepage={team.teamFeedAsHomepage}
				/>
			)}
		</div>
	);
};

const SVTeamHomeContainer = ({ children }: { children: React.ReactNode }) => {
	const isLoggedIn = useIsLoggedIn();
	const team = useAuthTeam();
	const hasTeamPrivileges = useAuthHasTeamPrivileges();
	const teamBoards = useAuthBoards()?.filter(
		(board) => board.ownershipType === BoardOwnershipType.TEAM,
	) as Board[];

	if (!hasTeamPrivileges || !isLoggedIn || !team) {
		if (team) {
			return <SVRedirect to="/team/users/" />;
		} else {
			return <SVRedirect to="/team/create/" />;
		}
	}

	return (
		<div className="flex relative -md:flex-col">
			<aside className="flex overflow-y-auto text-white sticky top-1 block md:h-[calc(100vh-4rem)] md:w-64 mx-[var(--page-margin)]">
				{teamBoards.length > 0 && (
					<div className="flex flex-col -md:flex-row -md:space-x-0 space-y-4">
						<_BoardSelection teamBoards={teamBoards} team={team as Team} />
					</div>	
				)}
			</aside>	
			<main className="flex-1 py-4 ml-10 -md:ml-0">{children}</main>
		</div>	
	);
};

export default SVTeamHomeContainer;
